<template>
  <div>
    <h4>Famille de médicaments</h4>
    
    <data-table
      :data-source="familles"
      :headers="headers"
      table-class="table-striped table-hover"
    >
      <template slot="actions">
        <a
          href="javascript:void(0)"
          class="btn btn-outline-primary btn-icon"
          data-toggle="modal"
          data-target="#add-famille-medicament"
        >
          <i class="icofont icofont-plus" />
        </a>
      </template>
    </data-table>
        
        
        
    <modal
      title="Ajout de famille de médicament"
      id="add-famille-medicament"
      :footer="false"
    >
      <form
        class="form-horizontal"
        @submit.prevent="adding"
      >
        <div
          class="alert alert-danger"
          v-if="has_error"
        >
          {{ error_msg }}
        </div>
        <div class="form-group">
          <label for="add-libelle">Libelle</label>
          <input
            id="add-libelle"
            name="add-libelle"
            type="text"
            class="form-control"
            v-model="libelle"
          >
        </div>
            
        <div class="form-group text-right">
          <button
            type="submit"
            class="btn btn-primary"
          >
            Ajouter
          </button>
        </div>
      </form>
    </modal>

    <modal
      title="Modification d'une famille de médicament"
      id="update-famille-medicament"
      :footer="false"
    >
      <form
        class="form-horizontal"
        @submit.prevent="editing"
      >
        <div
          class="alert alert-danger"
          v-if="has_error"
        >
          {{ error_msg }}
        </div>
        <div class="form-group">
          <label for="update-libelle">Libelle</label>
          <input
            id="update-libelle"
            name="update-libelle"
            type="text"
            class="form-control"
            v-model="libelle"
          >
        </div>
                
        <div class="form-group text-right">
          <button
            type="submit"
            class="btn btn-primary"
          >
            Enregistrer
          </button>
        </div>
      </form>
    </modal>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'
import DataTable from '../../../../components/dataTable/local.vue'
import { COMPONENT_TYPE, TEXT_TYPE } from '../../../../components/dataTable/dataType'
import Modal from '../../../../components/modal.vue'
import { ADD_FAMILLE_MEDICAMENT, UPDATE_FAMILLE_MEDICAMENT } from '../../../../graphql/sanitaire'
const Actions = () => import('../../../../components/admin/sanitaire/medicament/familleActions.vue')
export default {
    components: { DataTable, Modal},
    data(){
        return {
            libelle: null,
            has_error: false,
            error_msg: null
        }
    },
    watch: {
        selectedType: {
            handler(){
                if(this.selectedType !== null) {
                    this.libelle = this.selectedType.libelle
                }
            },
            deep: true
        }
    },
    methods: {
        ...mapMutations({
            done: 'DONE',
            setSelectedObject: 'SET_SELECTED_OBJECT'
        }),
        initForm(){
            this.setSelectedObject(null)
            this.libelle = null
            this.has_error = false
            this.error_msg = null
        },
        adding(){
            let data = {
                libelle: this.libelle,
            }
            this.$apollo.mutate({
                mutation: ADD_FAMILLE_MEDICAMENT,
                variables: {
                    "famille": {
                        ...data
                    }
                },
                update: (d) => {
                    console.log(d)
                    console.log(`Famille ${this.libelle} add successfully`)
                }
              }).then(() => {
                this.initForm()
                this.done()
            }).catch((error) => {
                console.error(error)
                this.has_error = true
                this.error_msg = error
            })
        },
        editing(){
            let data = {
                libelle: this.libelle,
            }
            this.$apollo.mutate({
                mutation: UPDATE_FAMILLE_MEDICAMENT,
                variables: {
                    "famille": {
                        ...data
                    },
                    "uid": this.selectedType.uid
                },
                update: (d) => {
                    console.log(d)
                    console.log(`Famille medicament ${this.libelle} on ${this.selectedType.uid} updated successfully`)
                }
              }).then(() => {
                this.initForm()
                this.done()
            }).catch((error) => {
                console.error(error)
                this.has_error = true
                this.error_msg = error
            })
        }
    },
    computed: {
        ...mapGetters({
            familles: 'sanitaire/familleMedicaments',
            selectedType: 'selectedObject'
        }),
        headers(){
            return [
                {label: 'ID', name: 'uid', type: TEXT_TYPE},
                {label: 'Libelle', name: 'libelle', type: TEXT_TYPE},
                {label: 'Actions', name: 'action', type: COMPONENT_TYPE, component: Actions}
            ]
        }
    }
}
</script>

<style>

</style>